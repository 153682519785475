import React from 'react';
import Layout from '../components/Layout';

export default function CareersPage() {
  return (
    <>
      <Layout>
        <p>Hey! I'm the careers page.</p>
      </Layout>
    </>
  );
}
